<template>
  <div>
    <b-card class="w-100 py-2 px-4 mb-2" no-body>
      <section class="d-md-flex align-items-center justify-content-between">
        <div label="Indicador" class="w-100" v-if="template.indicator">
          <strong class="mr-2">Indicador: </strong><span>{{ template.indicator.name }}</span>
        </div>
        <div label="Peso" class="w-100">
          <strong class="mr-2">Peso: </strong>
          <span>{{ template.weight }}</span>
        </div>

        <div class="icon-action d-flex w-100 justify-content-end" v-if="!is_provider">
          <b-button variant="outline-primary" class="d-flex align-items-center" size="sm" v-if="template.id"
            v-b-toggle="[`template-${index}`]">
            <b-icon class="h6 mr-1 mb-0" icon="eye" />
            <span>Visualizar faixas</span>
          </b-button>
        </div>
      </section>
      <b-collapse :id="`template-${index}`" class="mb-4 mt-3">
        <div>
          <strong>Faixas</strong>
          <Ranges class="mt-2" ready :tracks="template.ranges" :minValue="template.minValue"
            :interval="template.interval" />
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Ranges from "@/pages/System/TemplateIndicators/Ranges";

export default {
  components: {
    Ranges,
  },
  props: {
    template: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["is_provider"]),
  },
};
</script>
