const isNumber = (evt) => {
  evt = evt ? evt : window.event;

  const charCode = evt.which ? evt.which : evt.keyCode;

  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  }

  return true;
};

const isNumberWithoutDot = (evt) => {
  evt = evt ? evt : window.event;

  const charCode = evt.which ? evt.which : evt.keyCode;

  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }

  return true;
};
export { isNumber, isNumberWithoutDot };
