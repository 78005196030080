<template>
  <div class="w-100">
    <div class="indicators w-100">
      <div class="indicators-list mt-3 w-100">
        <skeleton height="58px" grid="1" gap="10" :rows="5" v-if="loading" />
        <empty-list
          v-if="emptyTemplates && !loading"
          text="Não há templates cadastrados"
        />

        <card-template
          v-for="(template, index) in templates"
          :key="index"
          :index="index"
          :template="template"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CardTemplate from "./CardTemplate.vue";

export default {
  props: {
    template_id: {
      type: [String, Number],
      required: true,
    },
    selected_module: {
      type: Object,
      required: true,
    },
  },
  components: {
    CardTemplate,
  },
  data() {
    return {
      emptyTemplates: false,
      loading: false,
      templates: [],
    };
  },
  computed: {
    ...mapGetters(["is_provider"]),
  },
  methods: {
    ...mapActions(["get_all_template_indicator"]),

    getTemplates() {
      this.loading = true;
      this.get_all_template_indicator({
        module_id: this.selected_module.id,
        template_id: this.template_id,
      })
        .then(({ results, total }) => {
          this.templates = results;
          this.emptyTemplates = total === 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getTemplates();
  },
  watch: {
    selected_module: {
      handler() {
        this.getTemplates();
      },
      deep: true,
    },
    template_id: {
      handler() {
        this.getTemplates();
      },
      deep: true,
    },
  },
};
</script>
