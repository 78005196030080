const variantStatus = (status) => {
  let icon;
  let variant;
  let label;
  switch (status) {
    case "waiting":
      icon = "clock-fill";
      variant = "warning";
      label = "Aguardando avaliação";
      break;
    case "error":
      icon = "x-circle-fill";
      variant = "danger";
      label = "Houve um erro na avaliação";
      break;
    case "ok":
      icon = "check-circle-fill";
      variant = "success";
      label = "Avaliação ok";
      break;
    case "":
      icon = "dash-circle-fill";
      variant = "waiting";
      label = "Dados não sincronizados";
      break;
  }
  return {
    icon,
    variant,
    label,
  };
};
export { variantStatus };
