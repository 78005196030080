<template>
  <div class="w-100">
    <b-list-group
      v-for="(premise, index) in premises"
      :key="index"
      class="w-100 mb-2"
    >
      <b-list-group-item>
        <small class="mb-2 d-block text-break">
          {{ premise.description }}
        </small>
        <div class="d-md-flex gap-2 align-items-center justify-content-between">
          <div class="d-flex align-items-center gap-2">
            <b-button
              size="sm"
              :variant="
                opt.value === premise.status ? 'primary' : 'outline-primary'
              "
              v-for="(opt, index) in options"
              :key="index"
              class="px-2"
              @click="handleChangeStatus(premise, opt)"
            >
              {{ opt.text }}
            </b-button>
          </div>
          <b-button
            size="sm"
            class="ml-2 px-2 d-flex gap-2"
            variant="info"
            @click="handleOpenDrawer(premise)"
          >
            <b-icon icon="chat" />
            <span>{{ premise.comment_cnt }}</span>
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-sidebar
      id="sidebarpremise-analysis"
      backdrop-variant="light"
      backdrop
      right
      shadow
      align="bottom"
      width="600px"
    >
      <template #default="{ hide }">
        <drawer-content
          analisys
          :premise="selectedpremise"
          :hide="hide"
          ref="drawerCommentsAnalisys"
        />
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { variantStatus } from "@/helpers/variantStatus";
import DrawerContent from "../RequestPremises/DrawerContent.vue";

export default {
  components: {
    DrawerContent,
  },
  data() {
    return {
      service_id: "",
      loading: false,
      variantStatus,
      selectedpremise: "",
      emptypremises: false,
      selected: "",
      options: [
        { text: "Atendido", value: 3, variant: "success" },
        { text: "Não atendido", value: 2, variant: "danger" },
      ],
      premises: [],
    };
  },
  methods: {
    ...mapActions(["get_all_premises", "edit_premises"]),
    handleChangeStatus(premise, opt) {
      premise.status = opt.value;
      this.edit_premises({
        ...premise,
        status: opt.value,
        service_id: this.service_id,
      }).then(() => {
        this.handleGetpremises();
      });
    },
    handleOpenDrawer(premise) {
      this.selectedpremise = premise;
      this.$root.$emit("bv::toggle::collapse", "sidebarpremise-analysis");
      this.$refs.drawerCommentsAnalisys.getAllComments(premise);
    },
    handleGetpremises() {
      this.loading = true;
      this.get_all_premises({ service_id: this.service_id }).then(
        ({ data: { results } }) => {
          this.loading = false;
          this.emptypremises = results?.length === 0;
          this.premises = results;
        }
      );
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.handleGetpremises();
  },
};
</script>
