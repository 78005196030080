<template>
  <div class="p-3 text-left container-comment">
    <div class="d-flex align-items-start justify-content-between">
      <strong class="d-block mb-2">Pedido de premissa</strong>

      <b-button
        v-if="!analisys && premise.status !== 3"
        size="sm"
        :disabled="premise.status === 4"
        :variant="premise.status === 4 ? 'light' : 'secondary'"
        @click="premise.status !== 4 && handleOpenModal()"
      >
        {{ premise.status === 4 ? "Arquivada" : "Arquivar premissa" }}
      </b-button>
    </div>

    <div class="text-break" contenteditable>{{ premise.description }}</div>

    <strong class="d-block mt-3">Comentários</strong>
    <div class="py-3 list-comment" ref="comments">
      <div v-for="(comment, index) in messages" :key="index">
        <section v-if="comment.is_maat" class="mr-4">
          <section
            :class="!isDuplicateMaat(index) && 'mt-4'"
            class="
              d-md-flex
              align-items-center
              justify-content-start
              flex-md-row
            "
          >
            <span class="d-block" v-if="!isDuplicateMaat(index)">
              Maat -
              <small>
                {{ moment(String(comment.created_at)).format("DD/MM/YY") }}
              </small>
            </span>
          </section>
          <section class="mr-4">
            <b-alert variant="info" show class="mt-2 mb-0">
              <div class="text-break" v-html="renderHtml(comment)" />
            </b-alert>
          </section>
        </section>
        <section v-else class="ml-5">
          <section
            :class="!isDuplicateUser(index) && 'mt-4'"
            class="
              d-md-flex
              align-items-center
              justify-content-end
              flex-md-row
              text-right
              mr-2
            "
          >
            <span
              class="d-block text-right"
              v-if="!isDuplicateUser(index) && user.enterprise"
            >
              {{ user.enterprise.fantasy_name }} -
              <small>
                {{ moment(String(comment.created_at)).format("DD/MM/YY") }}
              </small>
            </span>
          </section>
          <section
            class="mr-1"
            :class="isDuplicateUser(index) && 'user-duplicate '"
          >
            <b-alert variant="primary" show class="mt-1 mb-0 text-break">
              <div v-html="renderHtml(comment)" />
            </b-alert>
          </section>
        </section>
      </div>
    </div>
    <div class="new-message" v-if="premise.status !== 4">
      <b-alert :show="error" variant="danger">
        Erro ao enviar mensagem
      </b-alert>

      <div class="w-100 d-block">
        <section class="mb-3 mb-md-0 w-100">
          <b-form-textarea
            class="mt-1"
            v-model="message"
            label="title"
            placeholder="Digite seu comentário"
            ref="commentArea"
          >
          </b-form-textarea>
        </section>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-3">
        <b-button variant="light" class="mr-2" size="sm" @click="hide()">
          Cancelar
        </b-button>
        <b-button variant="primary" size="sm" @click="handleSendMessage()">
          Comentar
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    analisys: {
      type: Boolean,
    },
    hide: {
      type: Function,
    },
    premise: {
      type: [Object, String],
    },
  },
  data() {
    return {
      message: "",
      messages: [],
      error: false,
    };
  },
  methods: {
    ...mapActions(["get_all_premise_comments", "add_premise_comment"]),
    renderHtml(comment) {
      return `
        <div class="d-flex align-items-start justify-content-between">
        <span>${comment.message} </span>
        <span style="font-size: 10px ;white-space: nowrap;">${this.moment(
          String(comment.created_at)
        ).format("HH:mm")}</span>
        </div>
      `;
    },
    handleOpenModal() {
      this.$bvModal.show("archive-premise");
    },
    isDuplicateUser(index) {
      if (index > 0) {
        return !this.messages[index - 1].is_maat;
      }
    },

    isDuplicateMaat(index) {
      if (index > 0) {
        return this.messages[index - 1].is_maat;
      }
    },
    scrollToEnd() {
      const comments = this.$refs.comments;
      this.$nextTick(() => {
        comments.scrollIntoView({ behavior: "smooth" });
        comments.scrollTop = comments.clientHeight;
      });
    },
    handleSendMessage() {
      this.add_premise_comment({
        premise: this.premise.id,
        message: this.message,
      }).then(({ data }) => {
        if (data.premise) {
          this.getAllComments({ id: data.premise });
        } else {
          this.error = true;
        }
        return;
      });
      this.message = "";
      this.$refs.commentArea.focus();
    },
    getAllComments(premise) {
      this.get_all_premise_comments({
        premise_id: premise.id,
      }).then(({ results }) => {
        this.messages = results.reverse();
        this.scrollToEnd();
      });
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style lang="scss" scoped>
.container-comment {
  height: calc(100% - 312px);
}
.list-comment {
  height: 100%;
  overflow: auto;
}
.new-message {
  bottom: 8px;
  width: calc(100% - 33px);
}
.maat-duplicate {
  margin-top: -20px;
}
.user-duplicate {
  margin-right: 46px;
  margin-top: -20px;
}
.date-message {
  font-size: 8px;
}
</style>
